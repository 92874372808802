// navbar, logo
.navbar {
  &-nav {
    .nav-link {
      color: var(--foreground);

      &:hover {
        color: var(--foreground-higher);
      }
    }

    .nav-item {
      &.is_active > .nav-link {
        font-weight: 700;
        color: var(--foreground-higher);

        // border-bottom: 1px solid var(--link);
      }

      .offcanvas.show & {
        margin-left: -0.5rem;
        padding-left: 0.5rem;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    // Suchfeld
    form > input[type="search"] {
      max-width: 10rem;
    }
  }

  &-custom {
    @extend .navbar-light;

    // SVG nav buttons
    .navbar-toggler {
      border: none;
      padding: var(--size-2);

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.navbar-brand {
  margin-bottom: 0;
  padding: 0;
  display: inline-block;

  a {
    display: inline-block;
    text-indent: -300%;

    // width: 15vmax;
    min-height: 4rem;
    vertical-align: middle;
    background: transparent url(../img/iw-logo.svg) 0 50% no-repeat;
    background-size: contain;
    overflow: hidden;

    // default / light / no pref
    @media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
      background-image: url(../img/iw-logo.svg);
    }

    [data-theme="default"],
    [data-theme="light"] & {
      background-image: url(../img/iw-logo.svg);
    }

    // dark
    @media (prefers-color-scheme: dark) {
      background-image: url(../img/iw-logo_neg.svg);
    }

    [data-theme="dark"] &,
    [data-theme="high"] & {
      background-image: url(../img/iw-logo_neg.svg);
    }
  }
}

[data-theme="default"],
[data-theme="light"] {
  .navbar-custom {
    @extend .navbar-light;
  }
}

[data-theme="dark"],
[data-theme="high"] {
  .navbar-custom {
    @extend .navbar-dark;
  }
}

[date-theme="high"] {
  .navbar {
    &-nav .nav-link {
      color: var(--link);

      &:hover {
        color: var(--link-higher);
      }
    }
  }
}
