// Repo: https://github.com/argyleink/gui-challenges/tree/main/theme-switch
// Article: https://web.dev/building-a-theme-switch-component/
// Demo: https://gui-challenges.web.app/theme-switch/dist/

.theme-toggle {
  --size: var(--size-4);

  background: none;
  border: none;
  padding: 0;
  inline-size: var(--size);
  block-size: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  outline-offset: 5px;

  & > svg {
    inline-size: 100%;
    block-size: 100%;
    stroke-linecap: round;

    // pointer-events: none;
  }

  @media (hover: none) {
    --size: var(--size-5);
  }
}
