// Butler regular
@font-face {
  font-family: butlerregular;
  src:
    url("../fonts/butler_regular-webfont.woff2") format("woff2"),
    url("../fonts/butler_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// DIN Light by Linotype
@font-face {
  font-family: "DINWebPro-Light W02 Regular";
  src:
    // PanEuropean larger files
    url("../fonts/5590864/439dfb28-34e7-4397-9e61-57b7792a1d51.woff2") format("woff2"),
    url("../fonts/5590864/bead37db-3e39-42f6-9231-9107b01dfbc1.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$font-family-iw: "DINWebPro-Light W02 Regular";
$font-family-header-iw: "butlerregular", georgia, palatino, "Palatino Linotype", serif;
$font-family-base: $font-family-iw, $font-family-sans-serif;

body {
  font-family: $font-family-base;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: "tnum";
  text-rendering: optimizelegibility;
  font-weight: 400;
  font-optical-sizing: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
.nav-link {
  font-family: $font-family-header-iw;
  font-weight: 500;
}

h1 {
  font-size: var(--font-size-fluid-3);

  &.title-block__heading {
    line-height: 1;
  }
}

h2 {
  font-size: var(--font-size-fluid-2);

  .block--heading_block & {
    line-height: 1;
  }
}

h3 {
  font-size: var(--font-size-fluid-1);
}

h4 {
  font-size: var(--font-size-fluid-0);
}

p {
  --font-size-fluid-0x: clamp(1rem, 1.5vw, 1.2rem);

  font-size: var(--font-size-fluid-0x);
  hyphens: auto;
}

b,
strong {
  font-weight: 500;
}
