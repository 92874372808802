html,
body {
  height: 100%;
}

// main
.wrap {
  @include grid;

  grid-template-rows: auto 1fr auto;
  height: 100%;

  & > header,
  & > main,
  & > footer {
    @extend .container;
  }

  & > header {
    margin-top: calc(var(--gap) * 2);
    margin-bottom: var(--gap);
  }

  & > main {
    // margins on blocks
    .block {
      &--image_block {
        margin-bottom: var(--gap);
      }

      &--heading_block {
        margin-bottom: var(--gap);
      }

      &--image_group_block {
        @include grid;
        @include gridAuto;

        place-items: center center;
      }
    }
  }
}

.content-type {
  // #5 Work Page
  &--workpage {
    > aside {
      // grid-area: related;
      margin-top: calc(var(--gap) * 3);

      @include grid;
      @include gridAuto;

      --col: 20rem;

      .section-title {
        @extend .grid-reset;
      }

      article {
        @include grid;

        grid-template-columns: 10rem 1fr;
        gap: calc(var(--gap) * 0.5);
        margin-bottom: calc(var(--gap) * 2);

        picture {
          width: 5rem;
        }
      }
    }

    & > nav {
      grid-area: breadcrumb;

      @include grid-reset;
    }
  }

  // #5 Work Index
  &--workindexpage {
    .related-content {
      &__list {
        @include grid;
        @include gridAuto;

        @include media-breakpoint-up(xl) {
          --col: 20rem;
        }
      }

      article {
        @include grid;
        @include gridAuto;

        row-gap: calc(var(--gap) * 0.5);
        margin-bottom: calc(var(--gap) * 2);
        align-content: flex-start;

        header {
          justify-self: center;
        }
      }
    }
  }

  // Home Page
  &--homepage {
    > section {
      margin-bottom: calc(var(--gap) * 2);
    }
  }

  // Standard Pages, (blog) index
  &--standardindexpage {
    > section {
      @include grid;
      @include gridAuto;

      gap: calc(var(--gap) * 4);

      article {
        @include flexColumn;

        justify-content: flex-start;

        picture {
          @include flexColumn;

          gap: 0.5rem;
          align-items: center;

          img {
            max-height: 276px;
          }
        }

        time {
          display: block;
          font-size: 90%;
        }

        // &[data-type="ig-video"] { }

        // main > h2 {
        //   hyphens: auto;
        // }
      }
    }
  }

  // Blog, single post
  // &--standardpage {
  //   max-width: 80ch !important;
  // }
}

// Angebot
.content__5 {
  > article {
    @include media-breakpoint-up(md) {
      @include grid;

      grid-template-rows: min-content auto;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--gap) calc(var(--gap) * 2);

      .block {
        &--heading_block {
          margin-bottom: 0;

          h2 & {
            margin-bottom: 0;
          }

          &:nth-of-type(-n+3) {
            grid-row: 1/2;
          }

          // "Unsere Partner"
          &:last-of-type {
            grid-column: 1/4;
            grid-row: 4;
          }
        }

        &--image_block {
          grid-row: 2/3;
        }

        &--paragraph_block {
          grid-row: 3;
        }

        // Partner-Logos
        &--image_group_block {
          grid-column: -1/1;
          grid-row: 5;

          --col: 14rem;
        }
      }
    }
  }
}

// Über uns
.content__20 {
  @include grid;

  > article {
    @include grid;

    @include media-breakpoint-up(md) {
      grid-template-columns: 13rem 15vw 1fr;
      gap: var(--gap) calc(var(--gap) * 3);

      .block {
        &--heading_block {
          align-self: flex-start;

          // writing-mode: vertical-rl;
          // transform: rotate(180deg);
          margin-bottom: 0;

          h2 {
            margin-bottom: 0;
          }

          h3 {
            margin-bottom: 0;
            text-align: right;
          }

          &:first-of-type {
            @include grid-reset;

            width: unset;
            transform: unset;
            writing-mode: unset;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: 15rem 20vw 1fr;
    }
  }
}

// Kontakt / Formular
.content__21 {
  .form-fields {
    @include grid;
    @include gridAuto;

    --col: 20rem;

    li:first-child {
      @include grid-reset;
    }

    // checkboxes
    li ul {
      list-style: none outside;
      padding-left: 0;
    }

    // textarea
    li:nth-last-child(2) {
      @include grid-reset;
    }
  }

  input[type="email"],
  textarea {
    background-color: transparent;
  }
}
