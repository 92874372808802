// Import Open Props (without their .css extension)
// https://open-props.style/
@import "./node_modules/open-props/src/props.sizes";
@import "./node_modules/open-props/src/props.fonts";
@import "./node_modules/open-props/src/props.easing";

// @import "./node_modules/open-props/src/props.aspects";

// open props colors
@import "./node_modules/open-props/src/props.gray";

:root {
  // Grid
  --gap: 1rem;
  --col: 15rem;
  --accent: var(--gray-5);
  --brand: var(--gray-4);

  color-scheme: light dark;
  accent-color: var(--accent);

  --border-width: calc(1rem * 0.1);

  // Fluid font size override
  --font-size-fluid-1: clamp(1.2rem, 4vw, 1.5rem);
}

// colors
@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
  :root {
    --border-color: var(--gray-2);
    --link: var(--gray-7);
    --link-higher: var(--gray-9);
    --background: var(--gray-0);
    --foreground: var(--gray-8);
    --middleground: var(--gray-6);
    --background-higher: var(--bs-white);
    --foreground-higher: var(--gray-9);
    --foreground-lower: var(--gray-7);
    --shadow-color: var(--gray-5);
    --background-transparent: rgb(206 212 218 / 70%);

    $navbar-light-color: rgb(var(--forground) 0.55);
    $navbar-light-hover-color: rgb(var(--forground) 0.75);
    $navbar-light-active-color: var(--bs-white);
    $navbar-light-disabled-color: rgb(var(--forground) 0.25);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --border-color: var(--gray-7);
    --link: var(--gray-2);
    --link-higher: var(--gray-3);
    --background: var(--gray-8);
    --foreground: var(--gray-2);
    --middleground: var(--gray-5);
    --background-higher: var(--gray-9);
    --foreground-higher: var(--bs-white);
    --foreground-lower: var(--gray-1);
    --shadow-color: var(--gray-7);
    --background-transparent: rgb(73 80 87 / 70%);
    --accent: var(--gray-3);
  }

  $navbar-dark-color: rgb(var(--foreground) 0.55);
  $navbar-dark-hover-color: rgb(var(--foreground) 0.75);
  $navbar-dark-active-color: var(--foreground);
  $navbar-dark-disabled-color: rgb(var(--foreground) 0.25);
  $navbar-dark-toggler-border-color: rgb(var(--foreground) 0.1);
}

[data-theme="light"] {
  --border-color: var(--gray-2);
  --link: var(--gray-7);
  --link-higher: var(--gray-9);
  --background: var(--gray-0);
  --foreground: var(--gray-8);
  --middleground: var(--gray-6);
  --background-higher: var(--bs-white);
  --foreground-higher: var(--gray-9);
  --foreground-lower: var(--gray-7);
  --shadow-color: var(--gray-5);
  --background-transparent: rgb(206 212 218 / 70%);
}

[data-theme="dark"] {
  --border-color: var(--gray-7);
  --link: var(--gray-2);
  --link-higher: var(--gray-3);
  --background: var(--gray-8);
  --foreground: var(--gray-2);
  --middleground: var(--gray-5);
  --background-higher: var(--gray-9);
  --foreground-higher: var(--bs-white);
  --foreground-lower: var(--gray-1);
  --shadow-color: var(--gray-7);
  --background-transparent: rgb(73 80 87 / 70%);
  --accent: var(--gray-3);
}

// Bootstrap overrides
$btn-white-space: nowrap;
$carousel-control-color: var(--link);
$card-bg: var(--background);
$card-header-bg: var(--background);
$offcanvas-color: var(--foreground);
$offcanvas-bg-color: var(--background-higher);
$transition-time: 0.6s;
$navbar-light-color: var(--foreground-lower);
$navbar-dark-color: var(--foreground-lower);
$navbar-light-hover-color: var(--foreground-higher);
$navbar-dark-hover-color: var(--foreground-higher);

// SVG nav buttons
$btn-close-color: #ccc;
