body {
  color: var(--foreground);
  background: var(--background);
}

a {
  color: var(--link);
  text-decoration: none;

  p &,
  ul &,
  li & {
    text-decoration: underline solid var(--accent) 1px;
    text-underline-offset: 0.2rem;
  }

  &:hover {
    color: var(--link-higher);
  }
}

img {
  @include img-fluid;
}

body > svg {
  display: none; // Hide SVG sprite put directly in body
}

// pagination
.pagination {
  .page-item.disabled .page-link {
    color: var(--middleground);
    background-color: var(--background);
  }

  .page-link {
    color: var(--link);
    background-color: var(--background-higher);
  }

  .page-item.active .page-link {
    border-color: var(--link);
    background-color: var(--foreground);
    color: var(--background);
  }
}

// pills
.nav-pills {
  .nav-link.disabled {
    color: var(--middleground);
  }

  .nav-link {
    color: var(--link);
    background-color: transparent;
  }

  .nav-link.active {
    outline: 1px solid var(--link);
    color: var(--link);
    background-color: transparent;
  }
}

// content
.title {
  &-block__ {
    &tags {
      margin-bottom: var(--gap);
    }
  }
}

// Blocks
.block {
  // document block
  &--document_block {
    margin-bottom: var(--gap);

    &:last-child {
      margin-bottom: calc(var(--gap) * 2);
    }
  }

  // heading
  &--heading_block {
    align-self: flex-end;
  }

  // embed
  &--embed_block {
    .responsive-object {
      position: relative;

      iframe {
        // aspect-ratio: var(--ratio-widescreen);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  // image block
  &--image_block {
    margin-inline: auto;
    max-inline-size: max-content;

    figcaption {
      font-size: 90%;
    }
  }
}

// publication list
.tab-pane dl ul {
  @extend .list-unstyled;
}

/* icons */
.icon {
  display: inline-block;
  vertical-align: middle;
  width: var(--size-3); // 1rem
  height: var(--size-3);
  fill: currentcolor;
  margin-top: -0.25rem;

  .is-active & {
    fill: var(--accent);
  }

  a & {
    fill: currentcolor;
  }

  a:hover & {
    fill: currentcolor;
  }

  .disabled a & {
    fill: var(--middleground);
  }

  // Wagtail Userbar icon nicht geändert
  &.wagtail-userbar-icon {
    fill: unset;
  }

  // Sizes
  &--lg {
    width: var(--size-6);
    height: var(--size-6);
  }

  &--xl {
    width: var(--size-8);
    height: var(--size-8);
  }
}

.btn-link {
  color: currentcolor;
}

// Footer
.wrap > footer {
  display: flex;
  gap: var(--gap);
  justify-content: space-between;
  padding-top: var(--gap);
  padding-bottom: var(--gap);

  .icon {
    width: var(--size-4);
    height: var(--size-4);
    margin-right: var(--gap);
  }
}

// Tags
.tag {
  @extend .badge;

  background-color: var(--link);
  color: var(--background);
  text-decoration: none;
  margin-bottom: 0.2rem;

  &:hover {
    color: var(--background-higher);
    background-color: var(--link-higher);
  }

  &--active {
    background-color: var(--link-higher);
    color: var(--background);

    &:hover {
      color: var(--background-higher);
    }
  }
}

// Slider
.carousel {
  // &-inner {
  //   display: flex;
  // }

  &-indicators {
    margin-bottom: 0;
  }

  &-inner {
    .content-type--workpage & {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  &-item {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: min-content;
    grid-template-areas: slide;
    justify-content: center;

    picture {
      display: flex;
      justify-content: center;
      width: 100%;

      img {
        max-height: 95vh;
      }
    }

    // home page slider w/ slogans
    picture,
    figcaption {
      .content__3 & {
        grid-area: slide;
      }
    }

    &.active {
      display: grid;
    }
  }

  &--caption {
    // home page w/ shadows
    .content__3 & {
      color: var(--gray-0);
      text-shadow: 3px 3px 5px rgb(52 52 52 / 75%);
      z-index: 3;
    }

    justify-self: center;
    text-align: center;
    max-width: 90%;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      max-width: 85%;
    }

    // figcaption overlay style (home page)
    &__overlay {
      font-size: var(--font-size-fluid-2);

      @include media-breakpoint-up(md) {
        font-size: var(--font-size-fluid-3);
      }

      font-family: $font-family-header-iw;
      font-weight: 600;
      align-self: center;
    }

    // figcaption short description style (projects)
    &__description {
      align-self: flex-end;
      margin-bottom: 2rem;
    }
  }

  // control next/prev icons
  button .icon {
    color: var(--middleground);

    // filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 20%));
  }
}
