/*!
* Interior Werkstatt
* https://interior-werkstatt.at/
* Copyright Krzysztof Jeziorny 2022
* https://jeziorny.net
*/

@import "variables";
@import "mixins";

// @import "../../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "bootstrap"; // chosen parts
@import "grid";
@import "typography";
@import "navbar";
@import "form";
@import "layout";
@import "style";
@import "sun-and-moon";
@import "theme-toggle";
@import "a11y";
@import "print";
