// Formulare
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  @extend .form-control;
}

form {
  section {
    margin-bottom: 1rem;
  }

  label {
    @extend .form-label;
  }

  .form-control {
    margin-right: 0.2rem;
  }

  &.grid {
    button {
      align-self: center;
      justify-self: start;
      grid-column: -1/1;
    }
  }
}

input,
input:focus {
  &[type="text"],
  &[type="search"] {
    color: var(--foreground);
    background-color: var(--background);
  }
}

::placeholder {
  color: var(--foreground);
}

.form-group {
  .form-control,
  .btn {
    margin-right: 0.2rem;
  }

  .chosen-container-single {
    a.chosen-single {
      height: inherit;
    }
  }

  input,
  a.btn {
    line-height: 1.4;
  }
}

fieldset {
  border: 1px solid var(--border-color);
  padding: calc(var(--gap) / 2);
  border-radius: $border-radius;

  label {
    margin-right: 0.2rem;
  }

  legend {
    font-size: 1.75rem;
    font-weight: 500;
  }
}

// Validating
.invalid-feedback {
  display: block;
}

.helptext {
  @extend .form-text;

  font-style: italic;
}
